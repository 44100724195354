<template>
  <main class="main">
    <slot />
  </main>
</template>

<style lang="less">
.main {
  position: relative;
  background-color: #fff;
}
</style>
